import styled from "styled-components";

export const WorkExperienceContainer = styled.div`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e3e3e3;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 860px;
    }
`;

export const WorkExperienceWrapper = styled.div`
    margin: 0 auto;
    align-items: center;
    padding: 0 50px;
    width: 100%;
    max-width: 1100px;
`;

export const WorkExperienceH1 = styled.h1`
    font-size: 2rem;
    margin-top: 24px;
    margin-bottom: 32px;
    color: #01bf71;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const WorkExperienceTitle = styled.h2`
margin-bottom: 12px;`;

export const WorkExperienceCompanyName = styled.h4`
margin-bottom: 12px;
color: #01bf71;`;

export const SubTitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
`;
