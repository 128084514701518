import React from "react";
import Icon1 from "../../images/azure-administrator-associate.png";
import Icon2 from "../../images/gitlab-certified-associate.png";
import Icon3 from "../../images/azure-fundamentals.png";
import {
    CertificationContainer,
    CertificationH1,
    CertificationWrapper,
    CertificationCard,
    CertificationIcon,
    CertificationH2,
    CertificationP,
    CertificationBtn,
    CertificationBtnWrap,
} from "./CertificationElements";

const CertificationSection = () => {
    return (
        <CertificationContainer id="certifications">
            <CertificationH1>My Certifications</CertificationH1>
            <CertificationWrapper>
                <CertificationCard>
                    <CertificationIcon src={Icon1} />
                    <CertificationH2>Azure Administrator</CertificationH2>
                    <CertificationP>Earned On: 08 July, 2021</CertificationP>
                    <CertificationBtnWrap>
                        <CertificationBtn
                            href="https://drive.google.com/file/d/1AN9Fb0luVzuFNO-yhHoKYQjG-mE9nc1V/view?usp=sharing"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Certification Link
                        </CertificationBtn>
                    </CertificationBtnWrap>
                </CertificationCard>
                <CertificationCard>
                    <CertificationIcon src={Icon2} />
                    <CertificationH2>
                        GitLab Certified Associate
                    </CertificationH2>
                    <CertificationP>Earned On: 27 April, 2021</CertificationP>
                    <CertificationBtnWrap>
                        <CertificationBtn
                            href="https://api.badgr.io/public/assertions/s9P9gfT1TAKiiyHS2gcuDg?identity__email=gupta.sourabh385%40gmail.com"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Certification Link
                        </CertificationBtn>
                    </CertificationBtnWrap>
                </CertificationCard>
                <CertificationCard>
                    <CertificationIcon src={Icon3} />
                    <CertificationH2>Azure Fundamentals</CertificationH2>
                    <CertificationP>Earned On: 18 Sept, 2020</CertificationP>
                    <CertificationBtnWrap>
                        <CertificationBtn
                            href="https://drive.google.com/file/d/1IZhEcMPpJExhWnceElE7jT-ak2r7sdEk/view?usp=sharing"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Certification Link
                        </CertificationBtn>
                    </CertificationBtnWrap>
                </CertificationCard>
            </CertificationWrapper>
        </CertificationContainer>
    );
};

export default CertificationSection;
