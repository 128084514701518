import amazonawslogo from "../../images/amazonaws-logo.png";
import azurelogo from "../../images/azure-logo.png";
import kuberneteslogo from "../../images/kubernetes-logo.svg";
import dockerlogo from "../../images/docker-logo.png";
import gitlablogo from "../../images/gitlab-logo.png";
import jenkinslogo from "../../images/jenkins-logo.png";
import helmLogo from "../../images/helm-logo.png"
import terraformlogo from "../../images/terraform-logo.png";
import ansiblelogo from "../../images/ansible-logo.png";
import sonarlogo from "../../images/sonar-logo.png";
import artifactorylogo from "../../images/artifactory-logo.png";
import nexuslogo from "../../images/nexus-logo.png";
import prometheuslogo from "../../images/prometheus-logo.svg";
import grafanalogo from "../../images/grafana-logo.png";

export const skillsObj = [
    {
        img: amazonawslogo,
        title: "AWS",
    },
    {
        img: azurelogo,
        title: "Azure",
    },
    {
        img: kuberneteslogo,
        title: "Kubernetes",
    },
    {
        img: dockerlogo,
        title: "Docker",
    },
    {
        img: gitlablogo,
        title: "Gitlab",
    },
    {
        img: jenkinslogo,
        title: "Jenkins",
    },
    {
        img: helmLogo,
        title: "Helm",
    },
    {
        img: terraformlogo,
        title: "Terraform",
    },
    {
        img: ansiblelogo,
        title: "Ansible",
    },
    {
        img: sonarlogo,
        title: "Sonarqube",
    },
    {
        img: artifactorylogo,
        title: "Artifactory",
    },
    {
        img: nexuslogo,
        title: "Nexus",
    },
    {
        img: prometheuslogo,
        title: "Prometheus",
    },
    {
        img: grafanalogo,
        title: "Grafana",
    },
];
