import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { SkillsContainer, SkillsWrapper, SkillsH1, SkillsCard, SkillsIcon, SkillsH4 } from "./SkillsElements";
import { skillsObj } from "./Data.js";

const Card = ({ skill }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    return (
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <SkillsCard onMouseEnter={() => setIsFlipped((prev) => !prev)}>
            <SkillsIcon src={skill.img} />
        </SkillsCard>
        <SkillsCard onMouseLeave={() => setIsFlipped((prev) => !prev)}>
          <SkillsH4>{skill.title}</SkillsH4>
        </SkillsCard>
      </ReactCardFlip>
    );
};

const SkillsSection = () => {
    
    return (
        <SkillsContainer id="skills">
            <SkillsH1>My Skills</SkillsH1>
            <SkillsWrapper>
                { skillsObj.map((item, index) => (
                    <Card skill={item} key={`card-${index}`} />
                ))}
            </SkillsWrapper>
        </SkillsContainer>
    );
};

export default SkillsSection;
