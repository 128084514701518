import styled from "styled-components";

export const SkillsContainer = styled.div`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e3e3e3;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 1350px;
    }
`;

export const SkillsWrapper = styled.div`
    margin: 0 auto;
    align-items: center;
    padding: 0 50px;
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 16px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 20px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    }
`;

export const SkillsH1 = styled.h1`
    font-size: 2rem;
    margin-top: 24px;
    margin-bottom: 32px;
    color: #01bf71;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const SkillsCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

export const SkillsIcon = styled.img`
    height: 75px;
    width: 75px;
    margin-bottom: 10px;
`;

export const SkillsH4 = styled.h4`
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
`;
