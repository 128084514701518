import React from "react";
import Icon1 from "../../images/udacity-logo.png";
import Icon2 from "../../images/udacity-logo.png";
import Icon3 from "../../images/skit-logo.png";
import {
    EducationContainer,
    EducationH1,
    EducationWrapper,
    EducationCard,
    EducationIcon,
    EducationH2,
    EducationP,
    EducationBtn,
    EducationBtnWrap,
    EducationH5,
    EducationUdacityH5
} from "./EducationElements";

const EducationSection = () => {
    return (
        <EducationContainer id="education">
            <EducationH1>My Qualifications</EducationH1>
            <EducationWrapper>
                <EducationCard>
                    <EducationIcon src={Icon1} />
                    <EducationH2>AWS Cloud Architect Nanodegree</EducationH2>
                    <EducationUdacityH5>Udacity</EducationUdacityH5>
                    <EducationP>June 2020 - June 2020</EducationP>
                    <EducationBtnWrap>
                        <EducationBtn
                            href="https://confirm.udacity.com/5FJUGKQD"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Degree Link
                        </EducationBtn>
                    </EducationBtnWrap>
                </EducationCard>
                <EducationCard>
                    <EducationIcon src={Icon2} />
                    <EducationH2>
                    Cloud DevOps Engineer Nanodegree
                    </EducationH2>
                    <EducationUdacityH5>Udacity</EducationUdacityH5>
                    <EducationP>May 2020 - June 2020</EducationP>
                    <EducationBtnWrap>
                        <EducationBtn
                            href="https://confirm.udacity.com/KGKPGLNG"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Degree Link
                        </EducationBtn>
                    </EducationBtnWrap>
                </EducationCard>
                <EducationCard>
                    <EducationIcon src={Icon3} />
                    <EducationH2>B.Tech. in Information Technology</EducationH2>
                    <EducationH5>Swami Keshvanand Institute of Technology Management & Gramothan, Jaipur</EducationH5>
                    <EducationP>August 2014 - May 2018</EducationP>
                    <EducationBtnWrap>
                        <EducationBtn
                            href="https://drive.google.com/file/d/1Ri2o_02LqBLN1P3M3EEBoTYvzpEMwkZ1/view?usp=sharing"
                            target="_blank"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={true}
                            dark={true}
                        >
                            Degree Link
                        </EducationBtn>
                    </EducationBtnWrap>
                </EducationCard>
            </EducationWrapper>
        </EducationContainer>
    );
};

export default EducationSection;
