export const aboutSectionObj = {
    id: "about",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "Who Am I",
    headline: "DevOps Engineer",
    description:
        "I hold Bachelor's of Technology degree in Information & Technology and I'm currently working as DevOps engineer where I help clients in adopting and implementing DevOps culture at their organization and project level. <br/> <br/>  When I'm isn't glued to computer screen then I spend most of my time in exploring new places. Hills vs beaches is still a big debate for me.",
    buttonLabel: "Contact me",
    buttonHref: "mailto:gupta.sourabh385@gmail.com",
    imgStart: false,
    img: require("../../images/svg-1.svg").default,
    alt: "Car",
    dark: true,
    primary: true,
    darkText: false,
};
