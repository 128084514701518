import React from "react";
import { ButtonA } from "../ButtonElement";
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    SubTitle,
    BtnWrap,
    Column2,
    ImgWrap,
    Img,
    ColorSubTitle,
} from "./InfoElements";

const InfoSection = ({
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headline,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,
    buttonHref,
}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>
                                    {headline}
                                </Heading>
                                <SubTitle darkText={darkText}>
                                    I hold Bachelor's of Technology degree in{" "}
                                    <ColorSubTitle>
                                        Information & Technology
                                    </ColorSubTitle>{" "}
                                    and I'm currently working as{" "}
                                    <ColorSubTitle>
                                        DevOps engineer
                                    </ColorSubTitle>{" "}
                                    where I help clients in adopting and
                                    implementing DevOps culture at their
                                    organization and project level. <br />{" "}
                                    <br /> When I'm isn't glued to computer
                                    screen then I spend most of my time in{" "}
                                    <ColorSubTitle>
                                        exploring new places
                                    </ColorSubTitle>
                                    . Hills vs beaches is still a big debate for
                                    me.
                                </SubTitle>
                                <BtnWrap>
                                    <ButtonA
                                        href={buttonHref}
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >
                                        {buttonLabel}
                                    </ButtonA>
                                </BtnWrap>
                            </TextWrapper> 
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    );
};

export default InfoSection;
