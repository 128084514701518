import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {
    aboutSectionObj
} from "../components/InfoSection/Data";
import CertificationSection from "../components/CertificationSection";
import Footer from "../components/Footer";
import EducationSection from "../components/EducationSection";
import WorkExperienceSection from "../components/WorkExperienceSection"
import SkillsSection from "../components/SkillsSection";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <InfoSection {...aboutSectionObj} />
            <WorkExperienceSection />
            <CertificationSection />
            <SkillsSection />
            <EducationSection />
            <Footer />
        </>
    );
};

export default Home;
