import React, { useState } from "react";
import { ButtonL } from "../ButtonElement";
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
} from "./HeroElements";
import Video from "../../videos/video.mp4";

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };
    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
            </HeroBg>
            <HeroContent>
                <HeroH1>Hi! I'm Sourabh</HeroH1>
                <HeroBtnWrapper>
                    <ButtonL
                        to="about"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                    >
                        Know more about me{" "}
                        {hover ? <ArrowForward /> : <ArrowRight />}
                    </ButtonL>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
