import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { MdWork, MdStarBorder } from "react-icons/md";
import "react-vertical-timeline-component/style.min.css";
import {
    WorkExperienceContainer,
    WorkExperienceWrapper,
    WorkExperienceH1,
    WorkExperienceTitle,
    WorkExperienceCompanyName
} from "./WorkExperienceElements";

const WorkExperienceSection = () => {
    return (
        <WorkExperienceContainer id="work-experience">
            <WorkExperienceH1>My Work Experience</WorkExperienceH1>
            <WorkExperienceWrapper>
                <VerticalTimeline lineColor={"#01bf71"} layout={"2-columns"}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Oct 2021 - Present"
                        iconStyle={{ background: "#01bf71", color: "#fff" }}
                        icon={<MdWork />}
                    >
                        <WorkExperienceTitle>DevOps Engineer</WorkExperienceTitle>
                        <WorkExperienceCompanyName>
                            Oracle India Private Limited
                        </WorkExperienceCompanyName>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="May 2018 - Sep 2021"
                        iconStyle={{ background: "#01bf71", color: "#fff" }}
                        icon={<MdWork />}
                    >
                        <WorkExperienceTitle>Technology Analyst</WorkExperienceTitle>
                        <WorkExperienceCompanyName>
                            Infosys Limited
                        </WorkExperienceCompanyName>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        iconStyle={{ background: "#01bf71", color: "#fff" }}
                        icon={<MdStarBorder />}
                    />
                </VerticalTimeline>
            </WorkExperienceWrapper>
        </WorkExperienceContainer>
    );
};

export default WorkExperienceSection;
